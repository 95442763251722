"use client";

import Vimeo from "@u-wave/react-vimeo";

import Container from "../layout/container";

export default function CommercialVideo() {
  return (
    <Container className="relative hidden min-h-[300px] px-0 py-24 lg:flex">
      <Vimeo
        responsive
        width={"100%"}
        height={"100%"}
        video="https://player.vimeo.com/video/879884541"
        className="flex aspect-video w-full max-w-[80vw] items-center justify-center bg-black object-fill lg:mx-auto"
      />
      {/* <iframe src="https://player.vimeo.com/video/879884541?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Gold Slayer Commercial"></iframe> */}
    </Container>
  );
}
